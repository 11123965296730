import React from "react";

import { PageWithAuth } from "@mcp/interfaces";
import type { NextPage } from "next";
import dynamic from "next/dynamic";

const WelcomePage = dynamic(() => import("@/containers/Welcome"));
const Home: PageWithAuth<NextPage> = () => {
  return <WelcomePage />;
};

Home.auth = true;
export default Home;
